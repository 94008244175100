// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/Users/dandy/Documents/urologe-rom/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/Users/dandy/Documents/urologe-rom/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imprint-js": () => import("/Users/dandy/Documents/urologe-rom/src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("/Users/dandy/Documents/urologe-rom/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("/Users/dandy/Documents/urologe-rom/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

